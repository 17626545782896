@import url("https://fonts.googleapis.com/css?family=Karla");
@import url("https://fonts.googleapis.com/css?family=Satisfy");
@import url("https://fonts.googleapis.com/css?family=Karla");

@media only screen and (max-width: 768px) {
  .App {
    font-family: sans-serif;
    padding: 40px 20px 80px;
    background-color: #fff;
    min-height: 100vh;
    height: 100%;
    margin: 0;
  }
}

@media only screen and (min-width: 768px) {
  .App {
    font-family: sans-serif;
    padding-left: 50px;
    padding-right: 50px;
    background-color: #fff;
    min-height: 100vh;
    height: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 450px) {
  .mobilemax {
    max-width: 350px !important;
  }
}

.bkg {
  background: #eeeeee; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f5f4f4,
    #eeeeee
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #f5f4f4,
    #eeeeee
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: 100%;
  margin: 0;
  min-height: 100vh;
}

.logofont {
  font-family: Satisfy !important;
  font-size: 22px !important;
}

@media only screen and (min-width: 768px) {
  .loginpagenomargin {
    margin-top: 20px;
  }
}

.MuiSlider-valueLabel {
  display: none;
}

li {
  list-style-type: none !important;
}

.howdy {
  font-size: 2.5rem;
  font-weight: 600;
  font-family: Karla;
  text-align: left;
}

.MuiInput-input {
  font-family: Karla;
  font-weight: 500;
}

input, .css-g1d714-ValueContainer {
  font-family: Karla !important;
  font-weight: 500;
}

.uniload {
  font-family: sans-serif;
  text-align: center;
  max-width: 300px;
  margin: auto;
  margin-top: 100px;
}

h1,
h2,
h3,
h4,
.Karla,
span,
p {
  text-align: left;
  font-family: "Karla", sans-serif !important;
}

.padding-left {
  padding-left: 20px;
}

.MuiStepper-root {
  padding: 0px !important;
}

.padding-right {
  padding-right: 20px;
}

.paddingaround {
  padding-left: 10px;
  padding-right: 10px;
}

.floatleft {
  float: left;
}

/* .MuiChip-root {
} */

.MuiBreadcrumbs-separator {
  color: #38f9d7;
}

.shipaddy {
  background: linear-gradient(to right, #e7cfae, #e7cfae),
    linear-gradient(to right, #e7cfae, #a6712c, #fcc378);
  background-size: 100% 0.1em, 0 0.1em;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
}

.shipaddy:hover,
.shipaddy:focus {
  background-size: 0 0.1em, 100% 0.1em;
}

.sectionheader {
  padding-bottom: 10px;
  padding-top: 25px;
  font-family: Karla;
  text-align: left;
}

a {
  text-decoration: none;
}

.alignitemleft {
  display: flex !important;
  margin-left: 50px;
}

.shipwrappbar {
  padding: 5px 0;
  background-color: white !important;
}

.preview {
  margin-top: 30px;
  text-align: center;
}

.navbar-item:hover,
.navbar-item:active {
  color: #4a4e69;
}

.navbar-item,
.navbar-item:visited {
  text-decoration: none;
  color: rgb(102, 102, 102);
  width: 185px;
  border-radius: 5px;
}

.blur {
  -webkit-mask-image: linear-gradient(to left, transparent 0.3%, black 8%);
  mask-image: linear-gradient(to left, transparent 0.3%, black 8%);
}

.MuiButton-contained {
  color: #000 !important;
  background-image: linear-gradient(to right, #43e97b 0%, #38f9d7 100%);
  font-weight: 600 !important;
  font-family: Karla sans-serif !important;
}

.btn {
  background-image: linear-gradient(
    to right,
    #43e97b 0%,
    #38f9d7 100%
  ) !important;
  color: black !important;
  border-radius: 0px 0px 4px 4px !important;
}

.iiz__img {
  display: block;
  height: 230px !important;
  width: 100% !important;
  object-fit: cover !important;
}

.iiz {
  width: 100% !important;
}

.iiz__btn {
  border-radius: 5px;
}

.Mui-disabled.MuiButton-contained {
  opacity: 0.3;
}

.MuiButton-outlined.MuiButton-root {
  padding: 7px 20px;
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  margin-top: 8px !important;
  color: darkslategrey !important;
}

::-moz-selection {
  background: rgb(105, 243, 135);
}
::-webkit-selection {
  background: rgb(105, 243, 135);
}
::selection {
  background: rgb(105, 243, 135);
}

/* .MuiCardContent-root.productcard {
  max-height: 60px;
  padding-top: 5px;
  padding-bottom: 5px;
} */

.copypasta {
  background-color: rgb(247, 247, 247);
  min-width: 250px;
  padding: 25px 45px 25px 45px;
  border-radius: 4px;
  white-space: pre-line;
  text-align: center;
  line-height: .9em !important;
  -moz-box-shadow: inset 0 0 3px #d4d4d4;
  -webkit-box-shadow: inset 0 0 3px #d4d4d4;
  box-shadow: inset 0 0 4px #d4d4d4;
}

@media only screen and (max-width: 600px) {
  .productmobilecard {
    width: 380px !important;
  }
  .MuiFab-root {
    left: 310px !important;
  }
}

@media only screen and (max-width: 450px) {
  .nomobile {
    display: none !important;
  }
}

.cardmax {
  width: 350px !important;
}

.gradient-bkg {
  background: #4e54c8; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #8f94fb,
    #4e54c8
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #8f94fb,
    #4e54c8
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.subtext {
  font-size: 0.9rem;
  color: #8f8f8f;
  margin-bottom: 10px;
}

.subtext-blk {
  font-size: 0.9rem;
  color: #000000;
  margin-bottom: 10px;
}

.subtext-blknomargin {
  font-size: 0.9rem;
  color: #000000;
  margin-bottom: 10px;
}

.subtext-sm-blknomargin {
  font-size: 0.85rem;
  color: #000000;
  margin-bottom: 10px;
}

.subtext-sm {
  font-size: 0.8rem;
  color: #8f8f8f;
  margin-bottom: 10px;
}

.mutetext {
  font-size: 0.9rem;
  color: #8f8f8f;
}
.mutertext {
  font-size: 0.9rem;
  color: #a5a5a5;
}

/* .MuiFab-colorInherit {
  background-color: #ECD3B3;
  color: #a6712c;
} */

.MuiFab-colorInherit:hover {
  background-color: rgb(214, 179, 133);
  color: #a6712c;
}

.pricing {
  line-height: 2rem;
}

.photo {
  height: 220px;
  width: 220px;
}

media {
  height: 140px;
}

.photo a:active,
.photo a:focus {
  outline: 1;
  border: 10px solid black;
}

.putline {
  border: 10px solid black;
}

.purple-text {
  color: rgb(93, 216, 120) !important;
}

.appbarroom {
  padding-bottom: 40px !important;
}

@media only screen and (min-width: 768px) {
  .MuiContainer-root {
    padding-left: 220px !important;
  }
}

@media only screen and (min-width: 768px) {
  .appbarsize {
    padding-left: 220px !important;
  }
}

.MuiContainer-maxWidthMd {
  /* min-width: 350px;
  max-width: 1220px;
  align-self: start; */
  flex-grow: 1;
}

/* .MuiContainer-maxWidthLg {
  min-width: 350px;
  max-width: 1220px;
  align-content: start;
  justify-content: start;
  align-self: start;
  margin-left: 200px;
} */

.space {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.floatright {
  float: right;
}

.little-space {
  margin-top: 20px !important;
  margin-bottom: 5px !important;
}

.tiny-space {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.micro-space {
  margin-top: 5px;
}


.MuiAvatar-circle {
  background-color: #a6712c;
  border: 2px solid #fff;
  border-radius: 18px;
  box-shadow: 0 0 2px #888;
}

.ModalOverlay {
  position: fixed;
  inset: 0px;
  background-color: black;
  z-index: 99999;
}

.ReactModal {
  max-width: 800px;
  margin: 0 auto;
}

.nogap {
  margin: "auto";
}

.selected {
  border: 1px solid blue;
}

.selected {
  border: 1px solid blue;
}

.disclaimer {
  font-size: 0.8rem;
  margin-bottom: 40px;
  color: #8f8f8f;
}

.centered {
  text-align: center;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.leftalign {
  text-align: left;
}

.gridList {
  width: 500;
  height: 450px "wrap";

  overflow: "hidden";
}

.gridList {
  width: 500;
  height: 450;
}

*,
*:after,
*:before {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
  border: 0 none;
  position: relative;
  outline: none;
}

h1 > a:before {
  content: "";
  background: #dcd2ff;
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: 0;
  left: 0;
  border-radius: 5px;
  transform: scaleX(0);
  animation: 1.4s forwards no-hover-v linear;
  animation-fill-mode: forwards;
  z-index: -1;
}
h1 > a:hover:before,
h1 > a:focus:before {
  animation: 0.5s forwards hover-v linear;
  animation-fill-mode: forwards;
}
@keyframes hover-v {
  0% {
    transform: scaleX(0);
    height: 5px;
  }
  45% {
    transform: scaleX(1.05);
    height: 5px;
  }
  55% {
    height: 5px;
  }
  100% {
    transform: scaleX(1.05);
    height: 2.3rem;
  }
}
@keyframes no-hover-v {
  0% {
    transform: scaleX(1.05);
    height: 2.3rem;
  }
  45% {
    height: 5px;
  }
  55% {
    transform: scaleX(1.05);
    height: 5px;
    opacity: 1;
  }

  100% {
    transform: scaleX(0);
    height: 5px;
    opacity: 0.02;
  }
}

.DemoPickerWrapper {
  padding: 0 12px;
  font-family: "Source Code Pro", monospace;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  background: white;
  margin: 24px 0 48px;
  width: 100%;
}

.DemoPicker {
  font-size: 18px;
  border-radius: 3px;
  background-color: white;
  height: 48px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  border: 0;
  width: 100%;
  color: #6772e5;
  outline: none;
  background: transparent;

  -webkit-appearance: none;
}

.paycell {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 20px;
  margin-right: 10px;
}

label.pay {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}

button.pay {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

button.pay:hover {
  color: #fff;
  background-color: #7795f8;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

input.pay,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

input.pay::placeholder {
  color: #aab7c4;
}

input.pay:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}

/* .menuoffset {
  margin-left: 200px !important;
} */

.papertexture-beige {
  background-image: url("https://i.ibb.co/TqRZpD2/beige-paper.png");
  background-repeat: "repeat" !important;
  background-position: "repeat" !important;
}

.summaryheaderbkg {
  background-position: "cover";
  width: 100%;
}

.is-active {
  color: #140a06 !important;
  background-image: linear-gradient(to right, #43e97b 0%, #38f9d7 100%);
}

.MuiStepper-horizontal {
  padding: 5px;
}

.GiftsModal__open {
  display: flex;
  justify-content: start;
  align-items: start;
  padding-top: 8px;
  padding-left: 10px;
  padding-right: 15px;
  height: 2.4rem;

  border: none;
  border-radius: 6px;

  transition: background-color 0.2s ease-out;
}

.SettingsModal__open {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 2.75rem;
  height: 2.75rem;
  background-color: transparent;

  border: none;
  border-radius: 50%;

  transition: background-color 0.2s ease-out;
}

.SettingsModal__open:hover,
.SettingsModal__open:focus,
.SettingsModal__open:active {
  outline: none;
  background-color: rgba(0, 0, 0, 0.15);
}

.SettingsModal__logout {
  font-size: 1.25rem;
  font-weight: 500;
  text-align: left;
  background: none;
  border: none;
  transition: color 0.2s ease-out;
}

.SettingsModal__logout:hover,
.SettingsModal__logout:focus {
  outline: none;
  color: hsl(227, 99%, 65%);
}

.MuiGridListTile-tile {
  border-radius: 6px;
}

.MuiDrawer {
  width: 220px;
}

@import url("https://fonts.googleapis.com/css2?family=Karla&display=swap");

.flex {
  display: flex;
}
